.Form{
    height: auto;
    padding-bottom: 20px;
    .blocForm{
        width: 95%;
        .Name{
            display: flex;
            justify-content: space-between;
            margin-left: 5%;
            margin-bottom: 5px;
            .nameLabel{
                color: $primary-color;
            }
            .nameArea{
                width: 65%;
            }
        }
        .Mail{
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
            margin-left: 5%;
            .mailLabel{
                color:$primary-color;
            }
            .mailArea{
                width: 65%;
            }
        }
        .Message{
            display: flex;
            justify-content: space-between;
            margin-left: 5%;
            margin-bottom: 5px;
            align-items: center;
            .messageLabel{
                color: $primary-color;
            }
            .messageArea{
                width: 65%;
                height: 100px;
            }
        }
    }
    .button{
        margin-top: 10px;
        padding-bottom: 10px;
    }
}