.homePage-centrale{
    background-image: url(../../Assets/images/code-informatique.webp);
    background-size:cover;
    background-position: center;
    background-attachment:fixed;

    .navigation{
        a.home.active{
            text-decoration: underline;
            font-size: 16px;
            color:deepskyblue;
        }
        a.offres:hover, a.contact:hover {
            font-size: 18px;
            transition: font-size 0.5s
        }
    }
    
    .blocHome{
        padding-top: 10px;
        display: flex;
        flex-direction: column ;
        .Intro{
            margin-left:5% ;
            height: auto;
            width: 90%;
            border-radius: 20px;
            background-color: black;
            margin-top: 10px;
            box-shadow:  1px 1px 10px 5px deepskyblue;
                @keyframes colorChange {
                    0% { color: rgb(246, 5, 178); }
                    25% { color: rgb(43, 23, 222); }
                    50% { color: rgb(12, 208, 238); }
                    75% { color: rgb(43, 23, 222); }
                    100% { color: rgb(246, 5, 178); }
                }
                .animated-text {
                    animation: colorChange 6s infinite;
                    background-color: black;
                    border-radius: 10px;
                    margin-left: 5%;
                    margin-right: 5%;
                }
                .liens{
                    color: deepskyblue;
                }
                a.liens:hover{
                    color: deeppink;
                }
                p{
                    margin: 5%;
                    width: 90%;
                }
        }
        .slide{
            margin-top: 20px;
        }
        .homePresentation {
            position: relative;
            background-color: black;
            box-shadow:  1px 1px 10px 5px deepskyblue;
            margin-left: 5%;
            margin-right: 5%;
            border-radius: 20px;
            margin-top: 20px;
            margin-bottom: 20px;
            height: 300px;
            overflow: hidden;
            
            p {
                position: absolute;
                margin-left: 10%;
                transform: translateX(-50%);
                width: 80%;
                /*overflow: hidden;*/
                animation: scroll 50s linear infinite;
            }
            
        
            @keyframes scroll {
                0% {
                    transform: translateY(100%); // Position initiale en bas du cadre
                }
                100% {
                    transform: translateY(-100%); // Position finale en haut du cadre
                }
            }
        }
    }
}