.footer{
    width: 100%;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    padding-bottom: 10px;
    bottom: 0;
    h2.Name-entreprise{
        font-size:24px;
        margin:10px
    }
    .bloc-reseau{
        width: 100%;
        display: flex;
        justify-content: space-around;
        padding: 1%;
        
    }
    .bottomFooter{
        span{
            font-size: 11px;
            
            &:hover{
                font-size:14px;
                color: deeppink; // Couleur du texte au survol
                transition: font-size 0.5s 
            }
            cursor: pointer;
        } 
    }
    
}
    