.App-header {
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    .App-logo {
      width: 100px;
      height: 100px;
      animation: App-logo-spin infinite 5s forwards; /* Répéter l'animation indéfiniment */
      margin-left: 2%;
    }
    .slogan{
      margin-top: 20px;
      margin-left: 5%;
      margin-right: 5%;
      color: $primary-color;
      height: auto;
      font-size:larger;
    }
    
    .bloc-reseau{
      height: 100%;
      display: none;
      flex-direction: column;
      justify-content: space-around;
      animation: slideReseau 5s forwards;
    }
  }
  @keyframes App-logo-spin {
    0% {
      transform: rotate(0deg) 
    }
    100% {
      transform: rotate(360deg) 
    }
  }

  @keyframes slideReseau {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(-80%);
    }
}
  
  
  