.navigation{
    a.contact.active{
        text-decoration: underline;
        font-size: 16px;
        color:deepskyblue;
    }
    a.home:hover, a.offres:hover {
        font-size: 18px;
        transition: font-size 0.5s
    }
}

.Form {
    padding-top: 20px;
    background-image: url(../../Assets/images/réseau-tel.webp);
    background-size:cover;
    background-position: center;
    background-attachment:fixed;
    height: auto;
    @keyframes colorChange {
        0% { color: rgb(246, 5, 178); }
        25% { color: rgb(43, 23, 222); }
        50% { color: rgb(12, 208, 238); }
        75% { color: rgb(43, 23, 222); }
        100% { color: rgb(246, 5, 178); }
    }
  
    .animated-text {
        box-shadow:  1px 1px 10px 5px deepskyblue;
        animation: colorChange 6s infinite;
        background-color: $primary-color;
            margin-top: 0 ;
            padding: 1%;
            border-radius: 10px;
            margin-left: 20%;
            margin-right: 20%;
    }
    .mesContacts, .contactForm {
        box-shadow:  1px 1px 10px 5px $primary-color;
        background-color: $secondary-color;
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 50px;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        .telText, .mailText{
            h2{
                padding-left: 5%;
                padding-right: 5%;
                padding-top: 3%;
                color: deeppink;
            }
            p{
                padding-left: 4%;
                padding-right: 4%;
            }
        }
        
        .Téléphone{
            display: flex;
            flex-direction: column;
            padding-bottom: 8px;
            span{
                color: deepskyblue;
                font-size: 20px;
            }
        }
        
        .mailMail{
            display: flex;
            flex-direction: column;
            padding-bottom: 8px;
            span{
                color: deepskyblue;
                font-size: 20px;
                margin-bottom: 10px;
            }
        }
        .messageText{
            h2{
                padding-left: 5%;
                padding-right: 5%;
                padding-top: 3%;
                color: deeppink;
            }
            p{
                padding-left: 4%;
                padding-right: 4%;
            }
        }
        .Mail .form{
            display: flex;
            flex-direction: column;
            .name{
                display: flex;
                flex-direction: column;
                .nameLabel{
                    color: deepskyblue;
                }
                .nameInput{
                    width: 60%;
                    margin-left: 20%;
                    margin-top: 10px;
                }
            }
            .mail{
                display: flex;
                justify-content: space-around;
                flex-direction: column;
                .mailLabel{
                    color: deepskyblue;
                    margin-top: 10px;
                }
                .mailInput{
                    width: 60%;
                    margin-left: 20%;
                    margin-top: 10px;
                }
            }
            .message{
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                .messageLabel{
                    color: deepskyblue;
                    margin-top: 10px;
                }
                .messageArea{
                    width: 60%;
                    height: 100px;
                    margin-left: 20%;
                    margin-top: 10px;
                }
            }
            .button{
                width: 60%;
                margin-left: 20%;
                border-radius: 10px;
                padding-top: 10px;
                margin-bottom: 20px;
                background-color: $primary-color;
            } 
        }
    }
}
              
    
        