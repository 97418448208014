@media all and (min-width: 766px){ /*responsive Tablette*/
    .App-header .slogan{
        margin-left: 5%;
        margin-top: 30px;
        font-size: 24px;
        text-transform: uppercase;
    }
    .homePage-centrale{
        min-height: 100vh;
        .navigation{
            a{
                font-size: 20px;
            }
            a.home.active{
                font-size: 30px;
            }
            a.offres.active{
                font-size: 30px;
            }
            a.contact.active{
                font-size: 30px;
            }
            a.contact:hover, a.offres:hover{
                font-size: 30px;
            }
        } 
        .blocHome{
            flex-direction: row;
            .image-carousel img{
                margin-top: 10px;
                height: 400px;
                width: 90%;
            }
            .Intro{
                width: 90%;
                margin-top: 30px;
                margin-bottom: 30px;
                p{
                    font-size: 20px;
                }
                h1{
                    font-size: 40px;
                }
                h2{
                    font-size: 30px;
                }
            }
            .homePresentation{
                width: 90%;
                margin-top: 30px;
                height: 400px;
                p{
                    font-size: 20px;
                    animation: scroll 35s linear infinite;;
                }
            }
            
        }
    }
    
    .Offers{
        .navigation{
            a{
                font-size: 20px;
            }
            a.home.active{
                font-size: 30px;
            }
            a.offres.active{
                font-size: 30px;
            }
            a.contact.active{
                font-size: 30px;
            }
            a.contact:hover, a.home:hover {
                font-size: 30px;
            }
        }
        .offersPage-centrale{
            h1{
                font-size: 40px;
            }
            h2{
                font-size: 30px;
                padding-top: 10px;
                
            }
            p{
            font-size: 20px
            }
            .offersBloc{
                display: flex;
            }
            .site-vitrine,.site-ecommerce,.App-mobile{
                margin: 2%;
                width: 30%;
            }
        }
    }
    .contactPage{
        .navigation{
            a{
                font-size: 20px;
            }
            a.home.active{
                font-size: 30px;
            }
            a.offres.active{
                font-size: 30px;
            }
            a.contact.active{
                font-size: 30px;
            }
            a.offres:hover, a.home:hover {
                font-size: 30px;
            }
        }
        .Form{
            h1{
                font-size: 40px;
            }
            h2{
                font-size: 30px;
            }
            .mesContacts{
                min-height: auto;
                p{
                    padding-top: 20px;
                    font-size: 24px;
                }
                
                .Téléphone{
                    margin-top: 50px;
                    margin-left: 25%;
                    margin-right: 25%;
                    padding-bottom: 20px;
                    span{
                        font-size: 40px;
                    }
                }
                .mailMail{
                    margin-top: 50px;
                    margin-left: 8%;
                    margin-right: 8%;
                    padding-bottom: 20px;
                    span{
                        font-size: 40px;
                    }
                }
            }
                
        }
            .contactForm{
                min-height: auto;
                p{
                    font-size: 24px;
                    padding-top: 20px;
                }
                .mailLabel , .messageLabel , .nameLabel{
                    font-size: 24px;
                }
                .mailInput{
                    height: 30px;
                }
                
        }
    }  
    .footer{
        h2.Name-entreprise{
            font-size: 40px;
        }
        span{
            font-size: 20px;
            .mentions{
                p{
                    font-size: 20px;
                }
            }
        }
        span:hover{
            font-size: 24px;
        } 
    }
}

@media all and (min-width: 1480px){  /*responsive Pc*/
    .App-header{
        .slogan{
            margin-left: 8%;
            margin-top: 50px;
            font-size: 35px;
        }
        .bloc-reseau{
            width: 200px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            margin-right: -10%;
            img{
                width: 48px;
                height: 48px;
                }
        }
    }
    .homePage-centrale {
        .blocHome{
            .Intro{
                width: 60%;
                margin-top: 120px;
            }
            .SlidePresentation{
                display: flex;
                flex-direction: column;
                .slide{
                    width: 650px;
                }
                .homePresentation{
                    margin-top: 30px;
                    width: 550px;
                    p{
                        font-size: 20px;
                        animation: scroll 35s linear infinite;;
                    }
                }
            }
        }
    }
    .offersPage-centrale .animated-text{
        width: 33%;
        margin-left: 33%;
    }
    .offersBloc{
        h2{
            padding-top: 50px;
        }
        p{
            margin-left: 15%;
            margin-right: 15%;
        }
    }
    .Form{
        h1.animated-text{
            width: 30%;
            margin-left: 35%;
        }
        .contactBloc{
            display: flex;
            justify-content: space-between;
            .mesContacts{
                margin-left: 2%;
                margin-right: 2%;
                .telNumber .Téléphone{
                    margin-right: 30%;
                    margin-left: 20%;
                }
            }
            .contactForm{
                margin-left: 0;
                margin-right: 2%;
                min-height: auto;
                .Mail{
                    margin-top: 130px;
                    margin-left: 15%;
                    margin-right: 15%;
                }
            }
        }
    }
    .footer{
        .topFooter{
            h2{
                font-size: 32px;
            }
        }
        .bloc-reseau{
            
            img{
                width: 48px;
                height: 48px;
                }
        }
        .bottomFooter{
            span{
                font-size: 16px;
            }
        }
    }
}
