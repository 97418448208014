.mentions{
    height: auto;
    p{
        margin-left: 20px;
        margin-right: 20px;
        height: auto
    }
    button{
        margin-bottom: 20px;
    }
}
