.offersPage-centrale{
    min-height: 100vh;
    padding-bottom: 20px;
}
.navigation{
    a.offres.active{
        text-decoration: underline;
        font-size: 16px;
        color: deepskyblue;
    }
    a.home:hover, a.contact:hover {
        font-size: 20px;
        transition: font-size 0.5s
    }
}

.offersPage-centrale{
   @keyframes colorChange {
    0% { color: rgb(246, 5, 178); }
    25% { color: rgb(43, 23, 222); }
    50% { color: rgb(12, 208, 238); }
    75% { color: rgb(43, 23, 222); }
    100% { color: rgb(246, 5, 178); }
  }
  .animated-text {
    box-shadow:  1px 1px 10px 5px deepskyblue;
    animation: colorChange 6s infinite;
    background-color: $primary-color;
    border-radius: 10px;
    margin-left: 10%;
    margin-right: 10%;
  }
    padding-top: 20px;
    background-image: url(../../Assets/images/code-informatique.webp);
    background-size:cover;
    background-position: center;
    background-attachment:fixed;
}

.offersBloc .site-vitrine,.site-ecommerce,.App-mobile{
    box-shadow: 0px 1px 20px  white;
    background-color: $secondary-color;
    margin-top: 30px;
    margin-right: 5%;
    margin-left: 5%;
    border-radius: 15px;
    overflow: hidden;
    h2{
        padding-top: 3%;
        color: deeppink;
        animation: slideInTitle 5s forwards;
    }
    p{
        padding-bottom: 10px;
        margin-right: 5%;
        margin-left: 5%;
        margin-bottom: 0;
        .cafe{
            padding: 2%;
            border-radius: 50%;
        }
    }
    span{
        font-weight: 600;
        font-size: 24px;
        color: deepskyblue;
    }
    .contactLink{
        cursor: pointer;
        transition: color 0.2s; 

        &:hover {
            color: deeppink; 
        }
    }
    .logoGoogle-animated {
        border-radius: 25px;
        box-shadow: 0px 1px 20px red; 
        margin-top: 5px;
        
        animation: slideInRight 15s linear infinite;
        
    }   
    .detective-animated {
        border-radius: 25px;
        box-shadow: 0px 1px 20px rgb(92, 44, 0); 
        margin-top: 5px;
        animation: slideInLeft 15s linear infinite;
    }
    @keyframes slideInTitle {
        0% {
            opacity: 0;
            transform: translateX(100%);
        }
        100% {
            opacity: 1;
            transform: translateX(0%);
        }
    }
    @keyframes slideInRight{
        0%{
            opacity: 0;
            transform: translateX(150%);
        }
        50% {

            opacity: 1;
        }
        100% {
            opacity: 0;
            transform: translateX(-150%);
        }
    }
    @keyframes slideInLeft {
        0% {
            opacity: 0;
            transform: translateX(-150%);
        }
        50% {

            opacity: 1;
        }
        100% {
            opacity: 0;
            transform: translateX(150%);
        }
    }
}    
