.navigation{
    width: 100%;
    height: 50px;
    background-color: black;
    display: flex;
    justify-content: space-around;
    align-items: center;
    a{
        color: $primary-color;
        text-decoration: none;
    }
    
}
.navigation.scrolled {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    /* Autres styles à appliquer lorsque le composant est fixé en haut */
  }
